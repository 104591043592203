<template>
  <div class="faq">
    <faq-item
      v-for="faqItem of faqItems"
      :key="faqItem.question"
      class="faq__faq-item"
      :question="faqItem.question"
      :answer="faqItem.answer"
    />
  </div>
</template>

<script>
import FaqItem from './components/faq-item';

export default {
  components: {
    FaqItem,
  },
  data() {
    return {
      faqItems: [
        {
          question: 'Welche Medien und Orte bieten Sie an?',
          answer: `Als Spezialmittler für Außenwerbung 
          bieten wir alle Out-of-home Werbeträger aller Anbieter bundesweit an. 
          Dies ist Ihr Vorteil bei einer Abwicklung über uns: 
          Für alle Prozesse - von der Anfrage bis zur Durchführung – 
          haben Sie nur einen Ansprechpartner!`,
        },
        {
          question: ' Wir sind ein kleines bis mittelständiges Unternehmen – macht da Außenwerbung überhaupt Sinn?',
          answer: `Eindeutig „JA“! – Gerade die verschiedenen Medien der Außenwerbung bieten kleinen 
          Budgets vielfältige Möglichkeiten punktuell zu werben – egal, um auf eine bevorstehende 
          Veranstaltung hinzuweisen oder Sie bei der Suche nach neuem Personal zu unterstützen – 
          wir finden in jedem Fall geeignete Werbeträge für Ihre Botschaft! Mit Außenwerbung 
          erreichen Sie eine mobile Zielgruppe. 
          Weitere Informationen zu diesem Thema finden Sie in unserem Downloadbereich.`,
        },
        {
          question: 'Wie aktuell sind die Standorte und Verfügbarkeiten in dem Standorttool?',
          answer: `Die Freizahlen werden jede Nacht neu aktualisiert – demnach arbeiten Sie 
          auf unserem Tool immer mit den tagesaktuellen Verfügbarkeiten der Werbeträger.`,
        },
        {
          question: `Ich möchte mir gerne eine Übersicht mit mehreren Orten erstellen. 
          Im Startbildschirm kann ich aber nur einen Ort angeben.`,
          answer: `Sie können am Ende im Warenkorb beliebig viele Orte und Flächen 
          zusammen exportieren bzw. ausdrucken. Sobald Sie einen Ort fertig selektiert 
          haben und Ihre Wunschstellen und –dekaden in den Warenkorb gefügt haben, können 
          Sie über den Button „Kriterien ändern“ einen neuen Ort, Medium und/oder Zeitraum 
          auswählen und Ihre Auswahl wieder in den Warenkorb legen. In der Warenkorbübersicht 
          finden Sie dann alle selektierten Flächen und Orte.`,
        },
        {
          question: 'Sind meine ausgewählten Standorte reserviert?',
          answer: `Nein, alle angebotenen Medien werden im sog. „Tagesgeschäft“ 
          abgewickelt und sind nicht reservierbar. Verfügbarkeiten ändern sich 
          demnach täglich. Erst nach Ihrem verbindlich unterschriebenen Auftrag 
          können wir Ihre Wunschflächen beim Anbieter zur Buchung beauftragen. 
          In dieser Zwischenzeit können andere Interessenten diese Flächen weiterhin buchen.`,
        },
        {
          question: 'Wie viele Plakate muss ich drucken lassen?',
          answer: `Bitte planen Sie mindestens 10 % Ersatzplakate je 
          Plakatlager ein – gerade im Herbst und im Winter können sich 
          Plakate witterungsbedingt lösen und dann muss schnell Ersatz geklebt werden. 
          Bei einem Druck ohne Ersatz, werden keine Ausfälle entschädigt.`,
        },
        {
          question: 'Welche Vorlaufzeit sollte ich einplanen?',
          answer: `Um einen reibungslosen Ablauf gewährleisten zu können 
          sollten Sie für Ihre Werbeaktion ca. 4 Wochen Vorlauf berücksichtigen. 
          Die Druckdaten müssen ca. 3 Wochen vor dem Start der 
          Aktion vorliegen, so dass davor noch Ihre Auswahl und ggf. Vorkasse gewährleistet wird.`,
        },
        {
          question: 'Was bedeutet Dekade?',
          answer: `Eine Dekade beinhaltet eine Laufzeit von 10 oder 11 Tagen. 
          Alle Großflächen (GF) und Ganzstellen (GS) werden dekadenweise gebucht. 
          Kürzere Laufzeiten sind nicht möglich! Die jeweiligen Laufzeiten der Dekaden 
          und Blöcke können Sie unserem Termin- und Dekadenplan entnehmen, 
          der Ihnen in unserem Downloadbereich zur Verfügung steht.`,
        },
        {
          question: 'Was bedeutet Block?',
          answer: `Damit alle Anschlagunternehmen Ihre Flächen reibungslos 
          kleben können, wurden einheitliche Klebeblöcke (hauptsächlich A- und B-Block) 
          eingeführt. Diese sind fest für jede Stadt definiert und können nicht verändert 
          bzw. ausgesucht werden. Die jeweiligen Laufzeiten der Dekaden und Blöcke können 
          Sie unserem Termin- und Dekadenplan entnehmen, 
          der Ihnen in unserem Downloadbereich zur Verfügung steht.`,
        },
        {
          question: 'Warum sind die Tagespreise so unterschiedlich?',
          answer: `Der Tagespreis jeder einzelnen Großfläche richtet sich 
          nach dem PPS (Plakatseher Pro Stelle | Kontakthäufigkeit der Stelle) – 
          je höher dieser ausfällt (und demnach sich die Kontakthäufigkeit erhöht), 
          desto höher ist auch der Tagespreis pro Fläche.`,
        },
        {
          question: 'Was ist ein PPS?',
          answer: `Der aus der Reichweitenstudie ma Plakat bekannte Kontaktbegriff 
          „Plakatseher pro Stelle“ (PpS) ist seit 1. Januar 2013 auch Parameter für 
          die Leistung einer einzelnen Plakatstelle. Die Angabe erfolgt als 
          „Plakatseher pro Stelle im Belegungszeitraum“ und ist die 
          Bruttokontaktsumme eines Werbeträgers beziehungsweise Werbeträgernetzes. (Quelle: FAW).`,
        },
        {
          question: ' Auf dem Foto zur Fläche sehe ich zwei Plakate nebeneinander. Welche ist meine ausgewählte?',
          answer: `Bei Doppelstandorten können Sie sich immer anhand der aufgeführten 
          Standortnummer orientieren: die Fläche mit z.B. 01/02 am Ende ist die linke, 
          die mit 02/02 die rechte Tafel. Auch bei Standorten, die übereinander angebracht sind, 
          gibt die Standortnummer Auskunft über die Position: 01/02 = obere Tafel | 02/02 = untere Position.`,
        },
        {
          question: 'Wo kann ich eine Druckerei für meinen Plakatdruck finden?',
          answer: `Gerne übernehmen wir für Sie den Druck der Plakate. 
          Auf Grund der hohen Druckauflagen über das ganze Jahr, arbeiten 
          wir mit einer Druckerei unseres Vertrauens zu unsagbar günstigen 
          Preisen zusammen. Zusätzlich können Sie damit die komplette Abwicklung in 
          unsere Hände geben und haben mehr Zeit für andere Dinge. 
          Außerdem verhindern Sie dann ggf. die Anlieferung falscher Plakate hinsichtlich Qualität usw.`,
        },
        {
          question: 'Was muss ich beachten, wenn ich meine Plakate selber drucken möchte?',
          answer: `Sollten Sie bzw. Ihre Druckerei die Plakate selber drucken, 
          dann müssen einige drucktechnische Hinweise beachtet werden. 
          Hierzu können wir Ihnen auf Anfrage gerne Unterlagen zur Verfügung stellen. 
          Bitte beachten Sie, dass Plakate, die nicht den 
          Vorgaben (z.B. Affichenpapier usw.) entsprechen, nicht plakatiert werden können.`,
        },
        {
          question: 'Wieso ist der Plakatdruck so anders?',
          answer: `Großflächen- und Ganzstellenplakate werden 3 Tage vor der Klebung 
          24 Stunden eingeweicht, damit sie am Klebetag ordnungsgemäß weiterverarbeitet 
          und angebracht werden können. Dementsprechend sind diese auch von der 
          Plakatreklamation ausgeschlossen, da sie nicht wieder verarbeitet werden können. 
          Restplakate können deshalb auch nicht an den Kunden geschickt werden.`,
        },
        {
          question: 'Wozu dienen Ihre Termine? Wie kann ich etwas reklamieren?',
          answer: `Auf Grund der umfangreichen Logistik im Außenwerbesektor – gerade 
          hinsichtlich der einzeln buchbaren Standorte – müssen Druckunterlagen-Schlusstermine 
          (21 Tage vor Beginn), Rücktrittfristen (90 Tage) pünktlich eingehalten werden. 
          Reklamationen können nur innerhalb der Laufzeit mit einem entsprechenden 
          Fotobeweis und schriftlich angenommen und bearbeitet werden. Produktionskosten werden nicht erstattet.`,
        },
        {
          question: 'Warum werden im Onlinetool nicht alle Werbeträger angezeigt?',
          answer: `Teilweise sind einige Standorte durch langfristige 
          Dauerwerbeverträge über Jahre ausgebucht.`,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.faq {
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow: auto;

  &__faq-item {
    &:nth-child(odd) {
      background-color: $section-gray-bg;
    }
  }
}
</style>
