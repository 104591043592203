<template>
  <div class="faq-item">
    <label class="faq-item__question">
      <input
        v-model="showAnswer"
        type="checkbox"
        class="faq-item__toggle-btn"
      >
      {{ question }}
    </label>
    <transition name="text">
      <p
        v-show="showAnswer"
        class="faq-item__answer"
      >
        {{ answer }}
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: String,
      default: '',
    },
    answer: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showAnswer: false,
    };
  },
};
</script>

<style lang="scss">
.faq-item {
  padding: 20px 20px;

  &__question {
    display: flex;
    align-items: center;

    font-weight: 700;
    cursor: pointer;
  }

  &__toggle-btn {
    @include icon(arrow-down);
    margin-right: 5px;

    cursor: pointer;
    transition: 200ms;

    &:checked {
      transform: rotateZ(180deg);
    }
  }

  &__answer {
    margin-top: 7px;
    padding-left: 22px;

    line-height: 1.6;
    transition: 200ms;
  }
}

.text-enter-active {
  animation: text-enter 500ms;
}

.text-leave-active {
  animation: text-enter 300ms reverse;
}

@keyframes text-enter {
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 300px;
  }
}
</style>
